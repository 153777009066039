/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import classnames from 'classnames';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { TiTick, TiCancel } from 'react-icons/ti';
import { useDispatch } from 'react-redux';

import classes from './styles.module.scss';
import {
  setCurrentFolder,
  updateFolder,
  setFolderToRemove,
} from '../../../redux/slices/foldersSlice';
import { resetCurrentPage } from '../../../redux/slices/videosSlice';
import folderClosed from '../../../assets/images/Folder_Closed.svg';
import folderOpened from '../../../assets/images/Folder_Opened.svg';

export default function Folder({ title, currentFolder, id }) {
  const [folderName, setFolderName] = useState(title);
  const [isEditName, setIsEditName] = useState(false);

  const dispatch = useDispatch();

  const handleFolderRename = () => {
    if (folderName.trim() === '') {
      setIsEditName(false);
      setFolderName(title);
      return;
    }
    dispatch(updateFolder({ folderId: id, folderName }));
    setIsEditName(false);
  };

  return (
    <div
      className={classnames(
        'd-flex',
        'align-items-center',
        'mb-7',
        classes.Folder,
        currentFolder.id === id && classes.active
      )}
    >
      <div className="symbol symbol-50px me-5">
        {currentFolder.id === id ? (
          <img
            className={classes.folderIcon}
            src={folderOpened}
            alt="Folder icon"
          />
        ) : (
          <img
            className={classes.folderIcon}
            src={folderClosed}
            alt="Folder icon"
          />
        )}
      </div>

      <div className="flex-grow-1">
        <span
          className={classnames(
            'text-dark',
            'fw-bolder',
            'fs-6',
            classes.folderName
          )}
        >
          {isEditName ? (
            <input
              className={classes.nameInput}
              type="text"
              value={folderName}
              onChange={(event) => setFolderName(event.target.value)}
            />
          ) : (
            <span
              className={classnames('text-hover-primary', classes.title)}
              onClick={() => {
                dispatch(resetCurrentPage());
                dispatch(setCurrentFolder({ id, title }));
              }}
            >
              {title}
            </span>
          )}
          {title !== 'All Videos' &&
            (isEditName ? (
              <div className={classes.actionIcons}>
                <TiTick
                  size={26}
                  className={classes.actionIcon}
                  onClick={handleFolderRename}
                />
                <TiCancel
                  size={26}
                  className={classes.actionIcon}
                  onClick={() => setIsEditName(false)}
                />
              </div>
            ) : (
              <div className={classes.actionIcons}>
                <BsPencil
                  className={classes.actionIcon}
                  onClick={() => setIsEditName(true)}
                />
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_delete_folder"
                  onClick={() => dispatch(setFolderToRemove(id))}
                >
                  <BsTrash className={classes.actionIcon} />
                </span>
              </div>
            ))}
        </span>
        {/* <span className="text-muted d-block fw-bold">Sub</span> */}
      </div>
    </div>
  );
}
