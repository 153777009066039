import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  selectFaces,
  deleteSelectedFaces,
} from '../../../redux/slices/analyticsSlice';
import styles from './styles.module.scss';

const EntitiesItem = ({ data, isChecked: checked }) => {
  const { face_paths: facePath, grp_id: faceId, query_id: projectId } = data;
  const dispatch = useDispatch();
  const fixPath = () => {
    return facePath[0].split('/').slice(6, facePath[0].length).join('/');
  };
  const url = `http://ec2-34-202-203-184.compute-1.amazonaws.com:5000//type/image/index/firstvideoproject/Antitrust/${fixPath()}`;
  const [isSelected, setIsSelected] = useState(checked);

  const getEntitiesName = () => {
    if (faceId < 10) return `E00${faceId}`;
    if (faceId >= 10) return `E0${faceId}`;
    if (faceId >= 100) return `E${faceId}`;
    return `E${faceId}`;
  };

  const onHandleChange = (e) => {
    const isChecked = e.target.checked;
    setIsSelected(e.target.checked);
    if (isChecked) {
      dispatch(
        selectFaces({
          facePath,
          faceId,
          projectId,
          faceName: getEntitiesName(),
        })
      );
    }
    if (isChecked === false) {
      dispatch(
        deleteSelectedFaces({
          facePath,
          faceId,
          projectId,
          faceName: getEntitiesName(),
        })
      );
    }
  };

  return (
    <li className={styles['entities-item']}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={styles['entities-label']}>
        <img className={styles['entities-img']} src={url} alt={faceId} />
        <p className={styles['entities-name']}>{getEntitiesName()}</p>
        <input
          className={styles['entities-checkbox']}
          type="checkbox"
          onChange={onHandleChange}
          checked={isSelected}
        />
      </label>
    </li>
  );
};

export default EntitiesItem;
