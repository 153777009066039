/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { useDispatch } from 'react-redux';

import { removeVideo } from '../../redux/slices/videosSlice';

export default function ConfirmVideoRemoval() {
  const dispatch = useDispatch();
  return (
    <div
      className="modal fade"
      id="kt_modal_delete_video"
      tabIndex="-1"
      aria-hidden="true"
      style={{ top: 75 }}
    >
      <div className="modal-dialog modal-m">
        <div className="modal-content rounded">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
            <div className="mb-5 text-center">
              <h1 className="mb-3">Are your sure?</h1>
              <div className="text-muted fw-bold fs-5">
                Video will be deleted permanently.
              </div>
            </div>

            <div className="d-flex flex-center flex-row-fluid pt-12">
              <button
                type="button"
                className="btn btn-light me-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                data-bs-dismiss="modal"
                type="submit"
                className="btn btn-danger"
                onClick={() => dispatch(removeVideo())}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
