import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { Portal } from 'react-portal';
import { useSelector, useDispatch } from 'react-redux';
import Filters from '../../components/Analytics/Filters';
import Entities from '../../components/Analytics/Entities';
import MatchedVideos from '../../components/Analytics/MatchedVideos';
import CreateNewFolder from '../../modals/CreateNewFolder';
// import { getVideos } from '../../redux/slices/videosSlice';
import { getFaces } from '../../redux/slices/analyticsSlice';
import classes from './styles.module.scss';

export default function AnalyticsPage() {
  const dispatch = useDispatch();
  const [sourceType, setSourceType] = useState('All Videos');

  const folders = useSelector((state) => state.folders.folders);

  /*   const dispatch = useDispatch(); */

  useEffect(() => {
    dispatch(getFaces());
  }, []);

  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div
        className="container-xxl"
        id="kt_content_container"
        style={{ maxWidth: 1600 }}
      >
        <div className="card card-xl-stretch mb-xl-8 pt-6">
          <div
            className={classnames(
              'card-body',
              'py-3',
              classes.analyticsContainer
            )}
          >
            <Filters
              folders={folders}
              sourceType={sourceType}
              setSourceType={setSourceType}
            />
            <Entities />
          </div>
        </div>
      </div>
      <MatchedVideos />
      <Portal>
        <CreateNewFolder />
      </Portal>
    </div>
  );
}
