/* eslint-disable import/named */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import classnames from 'classnames';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { TiTick, TiCancel } from 'react-icons/ti';
import { VscOpenPreview } from 'react-icons/vsc';
import { useDispatch } from 'react-redux';

import classes from './styles.module.scss';
import {
  // setCurrentFolder,
  renameSearch,
  setSearchToRemove,
} from '../../../../redux/slices/searchesSlice';
// import { resetCurrentPage } from '../../../../redux/slices/videosSlice';
import folderClosed from '../../../../assets/images/Folder_Closed.svg';
import folderOpened from '../../../../assets/images/Folder_Opened.svg';

export default function Search({ title, currentFolder, id }) {
  const [searchName, setSearchName] = useState(title);
  const [isEditName, setIsEditName] = useState(false);

  const dispatch = useDispatch();

  const handleSearchRename = () => {
    if (searchName.trim() === '') {
      setIsEditName(false);
      setSearchName(title);
      return;
    }
    dispatch(renameSearch({ title, searchName }));
    setIsEditName(false);
  };

  return (
    <div
      className={classnames(
        'd-flex',
        'align-items-center',
        'mb-7',
        classes.Search,
        currentFolder.id === id && classes.active
      )}
    >
      <div className="symbol symbol-50px me-5">
        {currentFolder.id === id ? (
          <img
            className={classes.folderIcon}
            src={folderOpened}
            alt="Folder icon"
          />
        ) : (
          <img
            className={classes.folderIcon}
            src={folderClosed}
            alt="Folder icon"
          />
        )}
      </div>

      <div className="flex-grow-1">
        <span
          className={classnames(
            'text-dark',
            'fw-bolder',
            'fs-6',
            classes.folderName
          )}
        >
          {isEditName ? (
            <input
              className={classes.nameInput}
              type="text"
              value={searchName}
              onChange={(event) => setSearchName(event.target.value)}
            />
          ) : (
            <span className={classnames(classes.title)}>{title}</span>
          )}
          {title !== 'All Videos' &&
            (isEditName ? (
              <div className={classes.actionIcons} style={{ width: 35 }}>
                <TiTick
                  size={26}
                  className={classes.actionIcon}
                  onClick={handleSearchRename}
                />
                <TiCancel
                  size={26}
                  className={classes.actionIcon}
                  onClick={() => setIsEditName(false)}
                />
              </div>
            ) : (
              <div className={classes.actionIcons}>
                <VscOpenPreview size={18} className={classes.actionIcon} />
                <BsPencil
                  className={classes.actionIcon}
                  onClick={() => setIsEditName(true)}
                />
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_delete_search"
                  onClick={() => dispatch(setSearchToRemove(title))}
                >
                  <BsTrash className={classes.actionIcon} />
                </span>
              </div>
            ))}
        </span>
        {/* <span className="text-muted d-block fw-bold">Sub</span> */}
      </div>
    </div>
  );
}
