/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import { BsTrash } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { setVideoToRemove } from '../../redux/slices/videosSlice';
import classes from './styles.module.scss';
import { STATIC_URL } from '../../constants/main';

function addLeadingZeroes(id) {
  const countOfZeroesToAdd = 4 - `${id}`.length;
  let idString = 'ID';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < countOfZeroesToAdd; i++) {
    idString += 0;
  }
  return idString + id;
}

export default function Video({ video }) {
  const dispatch = useDispatch();

  return (
    <a
      href={STATIC_URL + video.internalFilePath}
      target="_blank"
      rel="noreferrer"
    >
      <div className={classes.Video}>
        <video src={STATIC_URL + video.internalFilePath} />
        <div className={classes.overlay}>
          <p>{video.originalFileName}</p>
          <p>Featured Videos</p>
        </div>
        <div
          className={classes.footer}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <span>{addLeadingZeroes(video.id)}</span>
          <BsTrash
            color="black"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_delete_video"
            className={classes.icon}
            onClick={(event) => {
              event.preventDefault();
              dispatch(setVideoToRemove(video));
            }}
          />
        </div>
      </div>
    </a>
  );
}
