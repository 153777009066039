import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// import ExamplePage from '../pages/ExamplePage';
import VideosPage from '../pages/Videos';
import ReadPage from '../pages/Read';
import AnalyticsPage from '../pages/Analytics';
// import PrivateRoute from "../containers/PrivateRoute";

const Routes = () => {
  useEffect(() => {
    document.body.className =
      'header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-enabled';
    document.body.id = 'kt_body';
  }, []);

  return (
    <Switch>
      {/* <PrivateRoute path="/dashboard" component={DashboardRoutes} /> */}
      {/* <Route exact path="/login" component={LoginPage} /> */}
      {/* <Route exact path="/forgot-password" component={ForgotPasswordPage} /> */}
      {/* <Route exact path="/reset-password" component={ResetPasswordPage} /> */}
      {/* <Route exact path="/error" component={ErrorPage} /> */}

      <Route path="/videos" exact component={VideosPage} />
      <Route path="/analytics" exact component={AnalyticsPage} />
      <Route path="/read" exact component={ReadPage} />
      <Redirect to="/videos" />
    </Switch>
  );
};

export default Routes;
