import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Portal } from 'react-portal';

import Video from '../../components/Video';
import ConfirmVideoRemoval from '../../modals/ConfirmVideoRemoval';
import FileOrDirectoryUpload from '../../modals/FileOrDirectoryUpload';
import CreateNewFolder from '../../modals/CreateNewFolder';
import { getVideos } from '../../redux/slices/videosSlice';
import classes from './styles.module.scss';

export default function VideosPage() {
  const [videosInARowCount, setVideosInARowCount] = useState('280px');

  const videos = useSelector((state) => state.videos.videos);
  const isLoading = useSelector((state) => state.videos.isLoading);
  const currentFolder = useSelector((state) => state.folders.currentFolder);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVideos({ folderId: currentFolder.id }));
  }, [currentFolder]);

  useEffect(() => {
    function defineVideosInARowCount() {
      if (window.innerWidth >= 2048 && window.innerHeight >= 1080) {
        setVideosInARowCount('280px');
      } else if (window.innerWidth <= 991) {
        setVideosInARowCount('280px');
      } else if (videos.length <= 6) {
        setVideosInARowCount('30%');
      } else if (videos.length <= 8) {
        setVideosInARowCount('22%');
      } else if (videos.length > 8) {
        setVideosInARowCount('18%');
      } else setVideosInARowCount('280px');
    }

    defineVideosInARowCount();

    window.addEventListener('resize', defineVideosInARowCount);

    return () => window.removeEventListener('resize', defineVideosInARowCount);
  }, [videos]);

  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div
        className="container-xxl"
        id="kt_content_container"
        style={{ maxWidth: 1600 }}
      >
        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">Videos</span>
              <span className="text-muted mt-1 fw-bold fs-7">
                {currentFolder.title}
              </span>
            </h3>
            <div
              className="card-toolbar"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-trigger="hover"
              title="Click to upload a video"
            >
              <a
                href="/"
                className="btn btn-sm btn-light btn-active-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_upload_video"
              >
                <span className="svg-icon svg-icon-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="11.364"
                      y="20.364"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(-90 11.364 20.364)"
                      fill="black"
                    />
                    <rect
                      x="4.36396"
                      y="11.364"
                      width="16"
                      height="2"
                      rx="1"
                      fill="black"
                    />
                  </svg>
                </span>
                Upload Video
              </a>
            </div>
          </div>
          <div
            className={classnames('card-body', 'py-3', classes.videosContainer)}
            style={{
              gridTemplateColumns: `repeat(auto-fill, ${videosInARowCount})`,
            }}
          >
            {videos &&
              videos.map((video) => <Video key={video.id} video={video} />)}
          </div>
          <button
            disabled={isLoading}
            type="submit"
            className={classnames(
              'btn',
              'btn-sm',
              'fw-bolder',
              'btn-primary',
              classes.loadMore
            )}
            onClick={() => {
              dispatch(getVideos({ folderId: currentFolder.id }));
            }}
          >
            {isLoading ? 'Loading...' : 'Load More'}
          </button>
        </div>
      </div>
      <Portal>
        <ConfirmVideoRemoval />
      </Portal>
      <Portal>
        <FileOrDirectoryUpload />
      </Portal>
      <Portal>
        <CreateNewFolder />
      </Portal>
    </div>
  );
}
