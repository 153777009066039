import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { Portal } from 'react-portal';
import classnames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { deleteFoundVideos } from '../../../redux/slices/analyticsSlice';
import classes from './styles.module.scss';
import MatchedVideosModal from '../../../modals/MatchedVideosModal';

export default function MatchedVideos() {
  const dispatch = useDispatch();
  const foundVideos = useSelector((state) => state.faces.foundVideos);
  const selectedFaces = useSelector((state) => state.faces.selectedFaces);
  // const isLoading = useSelector((state) => state.faces.isLoading)
  const [amount, setAmount] = useState(0);
  const [videoName, setVideoName] = useState([]);

  useEffect(() => {
    setAmount(foundVideos.length);
    if (foundVideos.length > 0) {
      const faces = selectedFaces.map((el) => el.faceName);
      return setVideoName(faces);
    }
    return setVideoName([]);
  }, [foundVideos]);

  useEffect(() => {
    return () => {
      dispatch(deleteFoundVideos());
      setVideoName([]);
      setAmount(0);
    };
  }, []);

  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div
        className="container-xxl"
        id="kt_content_container"
        style={{ maxWidth: 1600 }}
      >
        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1 text-muted">
                Matched Videos
              </span>
            </h3>
          </div>
          <div className="card-body py-3">
            <div className={classes['entities-box']}>
              <span className="text-muted">
                Entities Selected:{' '}
                {amount === 0 && <u style={{ color: 'blue' }}>0</u>}
              </span>
              <ul className={classes['entities-list']}>
                {videoName.map((el) => (
                  <li className={classes['entities-item']} key={nanoid()}>
                    {el}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <span className="text-muted">
                Number of Videos:{' '}
                {amount > 0 ? (
                  <span style={{ color: 'blue' }}>
                    <a
                      href="/"
                      className=""
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_matched_video"
                    >
                      <u>{amount}</u>
                    </a>
                  </span>
                ) : (
                  <span style={{ color: 'blue' }}>
                    <u>{amount}</u>
                  </span>
                )}
              </span>
            </div>
            <div className={classes.buttonContainer}>
              <button
                disabled
                type="button"
                className={classnames(
                  'btn',
                  'btn-sm',
                  'fw-bolder',
                  'btn-primary',
                  classes.loadMore
                )}
              >
                Review
              </button>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_save_search"
                className={classnames(
                  'btn',
                  'btn-sm',
                  'fw-bolder',
                  'btn-primary',
                  classes.loadMore
                )}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <Portal>
        <MatchedVideosModal />
      </Portal>
    </div>
  );
}
