/* eslint-disable import/named */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import { createSearch } from '../../redux/slices/searchesSlice';

import classes from './styles.module.scss';

export default function CreateNewFolder() {
  const [searchName, setSearchName] = useState('');
  const dispatch = useDispatch();

  const handleCreateSearch = () => {
    dispatch(createSearch(searchName));
    setSearchName('');
  };

  return (
    <div
      className="modal fade"
      id="kt_modal_save_search"
      tabIndex="-1"
      aria-hidden="true"
      style={{ top: 75 }}
    >
      <div className={classnames('modal-dialog modal-m', classes.modalDialog)}>
        <div className="modal-content rounded">
          <div className="modal-header justify-content-end border-0 pb-0 pt-3">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className="modal-body pt-0 pb-5 px-8">
            <div className="mb-5 text-left">
              <h1 className="mb-3">Save as Search</h1>
            </div>
            <div className={classes.inputContainer}>
              <label>
                Select a name for the search
                <input
                  type="text"
                  value={searchName}
                  onChange={(event) => setSearchName(event.target.value)}
                />
              </label>
            </div>
            <div className="d-flex flex-row-fluid pt-8">
              <button
                data-bs-dismiss="modal"
                type="submit"
                className={classnames('btn btn-primary', classes.createButton)}
                onClick={handleCreateSearch}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
