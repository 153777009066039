/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';

import Dropzone from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { BsArrowUpSquare } from 'react-icons/bs';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import Video from './Video';
import classes from './styles.module.scss';
import {
  addVideo,
  updateUploadStatus,
  removeVideoFromStopUploadingVideos,
  removeFromUploadStatus,
} from '../../redux/slices/videosSlice';

export default function FileOrDirectoryUpload() {
  const [isRunAnalytics, setIsRunAnalytics] = useState(true);
  const [selectedFolder, setSelectedFolder] = useState('All Videos');
  const [videos, setVideos] = useState([]);
  const [isFilePathsOnly, setIsFilePathsOnly] = useState(false);
  const [filePath, setFilePath] = useState('');

  const folders = useSelector((state) => state.folders.folders);
  const uploadStatus = useSelector((state) => state.videos.uploadStatus);
  const currentFolder = useSelector((state) => state.folders.currentFolder);
  const dispatch = useDispatch();

  const modalRef = useRef();

  const uploadVideos = (videoFiles) => {
    videoFiles.forEach((video) => {
      let formData;
      if (isFilePathsOnly) {
        formData = {
          analyticsRequested: isRunAnalytics,
          originalFilePath: filePath,
          originalName: video.name,
        };
        if (selectedFolder !== 'All Videos') {
          formData.folderId = selectedFolder;
        }
      } else {
        formData = new FormData();
        formData.append('video', video);
        formData.append('analyticsRequested', isRunAnalytics);
        if (selectedFolder !== 'All Videos') {
          formData.append('folderId', selectedFolder);
        }
      }
      dispatch(
        addVideo({
          formData,
          updateUploadStatus,
          removeVideoFromStopUploadingVideos,
          isFilePathOnly: isFilePathsOnly,
        })
      );
    });
  };

  const removeUploadedVideosOnClose = () => {
    setVideos((prevVideos) => {
      const newVideos = prevVideos.filter((video) => {
        if (
          !uploadStatus[video.name] ||
          (uploadStatus[video.name].progress !== 100 &&
            !uploadStatus[video.name].error)
        ) {
          return true;
        }
        dispatch(removeFromUploadStatus({ name: video.name }));
        return false;
      });
      return newVideos;
    });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const cr = entry.contentRect;
        if (cr.height === 0) {
          removeUploadedVideosOnClose();
        }
      }
    });
    if (modalRef.current) {
      resizeObserver.observe(modalRef.current);
    }

    return () => {
      if (modalRef.current) {
        resizeObserver.unobserve(modalRef.current);
      }
    };
  }, [modalRef, videos, removeUploadedVideosOnClose]);

  useEffect(() => {
    setSelectedFolder(currentFolder.id || 'All Videos');
  }, [currentFolder]);

  return (
    <div
      className={classnames('modal', 'fade', classes.FileOrDirectoryUpload)}
      id="kt_modal_upload_video"
      tabIndex="-1"
      aria-hidden="true"
      style={{ top: 75 }}
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content rounded" ref={modalRef}>
          <div
            className={classnames(
              'modal-header justify-content-between border-0 pb-1 px-10 mb-8',
              classes.header
            )}
          >
            <h1 className="mb-3">File or Directory Upload</h1>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className="modal-body pt-0 pb-8 px-5 px-xl-20">
            <div className={classes.mainContainer}>
              <div
                className={classnames('mb-5 text-center', classes.leftColumn)}
              >
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    setVideos((prevVideos) => {
                      if (prevVideos.length) {
                        const newVideos = [...prevVideos, ...acceptedFiles];
                        return newVideos;
                      }
                      return acceptedFiles;
                    });
                    uploadVideos(acceptedFiles);
                  }}
                  accept="video/*"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      className={classes.dropzoneContainer}
                    >
                      <div className={classes.dropzone}>
                        <input {...getInputProps()} />
                        <BsArrowUpSquare
                          size={38}
                          color="#04c8c8"
                          className={classes.arrowIcon}
                        />
                        <p>Drag files to upload</p>
                      </div>
                      <button type="button">Choose File</button>
                    </div>
                  )}
                </Dropzone>
                <div className={classes.options}>
                  <div className={classes.filePathsSwitcher}>
                    <label htmlFor="analytics">File Paths Only: </label>
                    <Toggle
                      id="analytics"
                      defaultChecked={isFilePathsOnly}
                      onChange={() =>
                        setIsFilePathsOnly((prevState) => !prevState)
                      }
                    />
                  </div>
                  {isFilePathsOnly && (
                    <div className={classes.filePath}>
                      <input
                        value={filePath}
                        onChange={(event) => setFilePath(event.target.value)}
                        type="text"
                        placeholder="Please enter a file path"
                      />
                    </div>
                  )}
                  <div className={classes.folderSelector}>
                    <label htmlFor="folder">Folder To Copy To: </label>
                    <select
                      name="folderToCopy"
                      id="folder"
                      value={selectedFolder}
                      onChange={(event) => {
                        setSelectedFolder(event.target.value);
                      }}
                    >
                      <option value="All Videos">All Videos</option>
                      {folders.map((folder) => {
                        return (
                          <option value={folder.id} key={folder.id}>
                            {folder.folderName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className={classes.analyticsSwitcher}>
                    <label htmlFor="analytics">Run Analytics: </label>
                    <Toggle
                      id="analytics"
                      defaultChecked={isRunAnalytics}
                      onChange={() =>
                        setIsRunAnalytics((prevState) => !prevState)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={classes.rightColumn}>
                {videos.length ? (
                  <div className={classes.uploading}>
                    <h2 className="mb-4">
                      {Object.values(uploadStatus).every(
                        (value) => value.progress === 100 || value.error
                      )
                        ? 'Uploads'
                        : 'Uploading'}
                    </h2>
                    {videos.map((video) => {
                      return (
                        <Video
                          key={video.path}
                          video={video}
                          setVideos={setVideos}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <h2 className="mb-4">No files are being uploaded now</h2>
                )}
              </div>
            </div>

            <div className="d-flex flex-row-fluid pt-12">
              <button
                type="button"
                className={classnames('btn btn-light', classes.closeButton)}
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
