import React from 'react';

import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import classes from './styles.module.scss';
import logo from '../../../assets/images/Logo.svg';

export default function Header() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <div
      style={{
        position: 'fixed',
        backgroundColor: 'white',
        left: 0,
        right: 0,
        height: 64,
        paddingLeft: 0,
      }}
      // d="kt_header"
      className={classnames('header', classes.Header)}
      // data-kt-sticky="true"
      // data-kt-sticky-name="header"
      // data-kt-sticky-offset="{default: '200px', lg: '300px'}"
    >
      <div
        className={classnames(
          'container-xxl',
          'd-flex',
          'align-items-center',
          'justify-content-between',
          classes.headerContainer
        )}
        id="kt_header_container"
      >
        <div
          style={{ position: 'absolute', left: 30 }}
          className="
                aside-logo
                d-none d-lg-flex
                flex-column
                align-items-center
                flex-column-auto
                py-10
              "
          id="kt_aside_logo"
        >
          <Link to="/">
            <img
              alt="Logo"
              src={logo}
              className={classnames('h-65px', classes.logo)}
            />
          </Link>
        </div>

        {isTabletOrMobile ? (
          <h1 className={classes.mobileTitle}>ABCD Repository</h1>
        ) : (
          <div
            className="
                  page-title
                  d-flex
                  flex-column
                  align-items-start
                  justify-content-center
                  flex-wrap
                  me-lg-2
                  pb-2 pb-lg-0
                "
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
          >
            <h1 className="text-dark fw-bolder my-0 fs-1 p-7">
              ABCD Repository
            </h1>

            {/* <ul className="breadcrumb fw-bold fs-base my-1">
              <li className="breadcrumb-item text-muted">
                <a href="../../demo7/dist/index.html" className="text-muted">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item text-dark">Default</li>
            </ul> */}
          </div>
        )}

        <div className="d-flex d-lg-none align-items-center ms-n2 me-2">
          <div
            className="btn btn-icon btn-active-icon-primary"
            id="kt_aside_toggle"
          >
            <span className="svg-icon svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                  fill="black"
                />
                <path
                  opacity="0.3"
                  d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>

          <a
            href="../../demo7/dist/index.html"
            className="d-flex align-items-center"
          >
            <img alt="Logo" src={logo} className="h-30px" />
          </a>
        </div>

        {/* <div className="d-flex flex-shrink-0">
          <div className="d-flex ms-3">
            <a
              href="/"
              className="
                      btn btn-flex
                      flex-center
                      bg-body
                      btn-color-gray-700 btn-active-color-primary
                      w-40px w-md-auto
                      h-40px
                      px-0 px-md-6
                    "
              tooltip="New Member"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_invite_friends"
            >
              <span className="svg-icon svg-icon-2 svg-icon-primary me-0 me-md-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="11.364"
                    y="20.364"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-90 11.364 20.364)"
                    fill="black"
                  />
                  <rect
                    x="4.36396"
                    y="11.364"
                    width="16"
                    height="2"
                    rx="1"
                    fill="black"
                  />
                </svg>
              </span>

              <span className="d-none d-md-inline">New Member</span>
            </a>
          </div>

          <div className="d-flex ms-3">
            <a
              href="/"
              className="
                      btn btn-flex
                      flex-center
                      bg-body
                      btn-color-gray-700 btn-active-color-primary
                      w-40px w-md-auto
                      h-40px
                      px-0 px-md-6
                    "
              tooltip="New App"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_create_app"
              id="kt_toolbar_primary_button"
            >
              <span className="svg-icon svg-icon-2 svg-icon-primary me-0 me-md-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z"
                    fill="black"
                  />
                  <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
                </svg>
              </span>

              <span className="d-none d-md-inline">New App</span>
            </a>
          </div>

          <div className="d-flex align-items-center ms-3">
            <div
              className="
                      btn btn-icon btn-primary
                      w-40px
                      h-40px
                      pulse pulse-white
                    "
              id="kt_drawer_chat_toggle"
            >
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z"
                    fill="black"
                  />
                  <rect x="6" y="12" width="7" height="2" rx="1" fill="black" />
                  <rect x="6" y="7" width="12" height="2" rx="1" fill="black" />
                </svg>
              </span>

              <span className="pulse-ring" />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
