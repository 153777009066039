/* eslint-disable react/no-array-index-key */
/* eslint-disable import/named */
import React, { useEffect } from 'react';

import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Portal } from 'react-portal';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SaveSearch from '../../../modals/SaveSearch';
import ConfirmSearchRemoval from '../../../modals/ConfirmSearchRemoval';
import { getFolders } from '../../../redux/slices/foldersSlice';

import Search from './Search';

import classes from './styles.module.scss';

export default function Searches() {
  const searches = useSelector((state) => state.searches.searches);
  const currentSearch = useSelector((state) => state.searches.currentSearch);
  // const folderError = useSelector((state) => state.folders.error);
  const dispatch = useDispatch();

  // const notifyError = (message) => toast.error(message);

  /*   useEffect(() => {
    if (folderError.message) {
      notifyError(folderError.message);
    }
  }, [folderError]);
 */
  useEffect(() => {
    dispatch(getFolders());
  }, []);

  return (
    <div
      className={classnames(
        'tab-pane',
        'fade',
        'show',
        'active',
        classes.Folders
      )}
      id="kt_aside_nav_tab_folders"
      role="tabpanel"
    >
      <div className="mx-5">
        <h3 className="fw-bolder text-dark mx-0 mb-10">Video Analytics</h3>

        <div className="mb-12">
          <h3 className="mb-7">Searches</h3>
          {searches &&
            searches.map((search, index) => (
              <Search
                key={index}
                id={index}
                title={search}
                currentFolder={currentSearch}
              />
            ))}
        </div>
      </div>
      <Portal>
        <SaveSearch />
      </Portal>
      <Portal>
        <ConfirmSearchRemoval />
      </Portal>
    </div>
  );
}
