/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import Folders from '../../Folders';
import Searches from '../../Analytics/Searches';
import classes from './styles.module.scss';

export default function index() {
  const history = useHistory();
  const location = useLocation();

  return (
    <div
      style={{ top: 65 }}
      id="kt_aside"
      className="aside aside-extended"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="auto"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_toggle"
    >
      <div
        className="
              aside-primary
              d-flex
              flex-column
              align-items-lg-center
              flex-row-auto
            "
      >
        <div
          className="
                aside-logo
                d-none d-lg-flex
                flex-column
                align-items-center
                flex-column-auto
                py-4
              "
          id="kt_aside_logo"
        />

        <div
          className="
                aside-nav
                d-flex
                flex-column
                align-items-center
                flex-column-fluid
                w-100
                pt-5 pt-lg-0
              "
          id="kt_aside_nav"
        >
          <div
            className="hover-scroll-y mb-10"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_aside_nav"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-offset="0px"
          >
            <ul className="nav flex-column">
              <li
                className="nav-item mb-2"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-placement="right"
                data-bs-dismiss="click"
                title="Videos"
              >
                <a
                  className="
                        nav-link
                        btn
                        btn-icon
                        btn-active-color-primary
                        btn-color-gray-400
                        btn-active-light
                        active
                      "
                  data-bs-toggle="tab"
                  href="#kt_aside_nav_tab_folders"
                  onClick={() => history.push('/videos')}
                >
                  <span className="svg-icon svg-icon-3x">
                    <svg
                      className={classes.svgButton}
                      style={{
                        fill:
                          location.pathname === '/videos' ? '#48e9e0' : '#000',
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 50 50"
                      width="50px"
                      height="50px"
                    >
                      <path d="M 12.8125 5 C 12.261719 5.050781 11.855469 5.542969 11.90625 6.09375 C 11.957031 6.644531 12.449219 7.050781 13 7 L 37 7 C 37.359375 7.003906 37.695313 6.816406 37.878906 6.503906 C 38.058594 6.191406 38.058594 5.808594 37.878906 5.496094 C 37.695313 5.183594 37.359375 4.996094 37 5 L 13 5 C 12.96875 5 12.9375 5 12.90625 5 C 12.875 5 12.84375 5 12.8125 5 Z M 7.8125 9 C 7.261719 9.050781 6.855469 9.542969 6.90625 10.09375 C 6.957031 10.644531 7.449219 11.050781 8 11 L 42 11 C 42.359375 11.003906 42.695313 10.816406 42.878906 10.503906 C 43.058594 10.191406 43.058594 9.808594 42.878906 9.496094 C 42.695313 9.183594 42.359375 8.996094 42 9 L 8 9 C 7.96875 9 7.9375 9 7.90625 9 C 7.875 9 7.84375 9 7.8125 9 Z M 2.8125 13 C 2.335938 13.089844 1.992188 13.511719 2 14 L 2 44 C 2 44.550781 2.449219 45 3 45 L 47 45 C 47.550781 45 48 44.550781 48 44 L 48 14 C 48 13.449219 47.550781 13 47 13 L 3 13 C 2.96875 13 2.9375 13 2.90625 13 C 2.875 13 2.84375 13 2.8125 13 Z M 4 15 L 46 15 L 46 43 L 4 43 Z M 20.1875 23 L 20.1875 35 L 30.8125 29 Z" />
                    </svg>
                  </span>
                </a>
              </li>
              <li
                className="nav-item mb-2"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-placement="right"
                data-bs-dismiss="click"
                title="Analytics"
              >
                <a
                  className="
                        nav-link
                        btn
                        btn-icon
                        btn-active-color-primary
                        btn-color-gray-400
                        btn-active-light
                        active
                      "
                  data-bs-toggle="tab"
                  href="#kt_aside_nav_tab_analytics"
                  onClick={() => history.push('/analytics')}
                >
                  <span className="svg-icon svg-icon-3x">
                    <svg
                      className={classes.svgButton}
                      fill={
                        location.pathname === '/analytics' ? '#48e9e0' : '#000'
                      }
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 50 50"
                      width="50px"
                      height="50px"
                    >
                      <path d="M 12.8125 5 C 12.261719 5.050781 11.855469 5.542969 11.90625 6.09375 C 11.957031 6.644531 12.449219 7.050781 13 7 L 37 7 C 37.359375 7.003906 37.695313 6.816406 37.878906 6.503906 C 38.058594 6.191406 38.058594 5.808594 37.878906 5.496094 C 37.695313 5.183594 37.359375 4.996094 37 5 L 13 5 C 12.96875 5 12.9375 5 12.90625 5 C 12.875 5 12.84375 5 12.8125 5 Z M 7.8125 9 C 7.261719 9.050781 6.855469 9.542969 6.90625 10.09375 C 6.957031 10.644531 7.449219 11.050781 8 11 L 42 11 C 42.359375 11.003906 42.695313 10.816406 42.878906 10.503906 C 43.058594 10.191406 43.058594 9.808594 42.878906 9.496094 C 42.695313 9.183594 42.359375 8.996094 42 9 L 8 9 C 7.96875 9 7.9375 9 7.90625 9 C 7.875 9 7.84375 9 7.8125 9 Z M 2.8125 13 C 2.335938 13.089844 1.992188 13.511719 2 14 L 2 44 C 2 44.550781 2.449219 45 3 45 L 47 45 C 47.550781 45 48 44.550781 48 44 L 48 14 C 48 13.449219 47.550781 13 47 13 L 3 13 C 2.96875 13 2.9375 13 2.90625 13 C 2.875 13 2.84375 13 2.8125 13 Z M 4 15 L 46 15 L 46 43 L 4 43 Z M 20.1875 23 L 20.1875 35 L 30.8125 29 Z" />
                    </svg>
                  </span>
                </a>
              </li>
              <li
                className="nav-item mb-2"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-placement="right"
                data-bs-dismiss="click"
                title="Read"
              >
                <a
                  className={classNames(
                    'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light',
                    location.pathname === '/read' && 'active'
                  )}
                  data-bs-toggle="tab"
                  href="#kt_aside_nav_tab_projects"
                  onClick={() => history.push('/read')}
                >
                  <span className="svg-icon svg-icon-3x">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13H13V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V13H8C7.4 13 7 13.4 7 14C7 14.6 7.4 15 8 15H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V15H16C16.6 15 17 14.6 17 14C17 13.4 16.6 13 16 13Z"
                        fill="black"
                      />
                      <path
                        d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="aside-secondary d-flex flex-row-fluid">
        <div className="aside-workspace my-5 p-5" id="kt_aside_wordspace">
          <div className="d-flex h-100 flex-column">
            <div
              className="flex-column-fluid hover-scroll-y"
              data-kt-scroll="true"
              data-kt-scroll-activate="true"
              data-kt-scroll-height="auto"
              data-kt-scroll-wrappers="#kt_aside_wordspace"
              data-kt-scroll-dependencies="#kt_aside_secondary_footer"
              data-kt-scroll-offset="0px"
            >
              <div className="tab-content">
                <div
                  className="tab-pane fade"
                  id="kt_aside_nav_tab_projects"
                  role="tabpanel"
                />

                <div
                  className="tab-pane fade"
                  id="kt_aside_nav_tab_subscription"
                  role="tabpanel"
                >
                  <div className="mx-5">
                    <div className="text-center pt-10 mb-20">
                      <h2 className="fs-2 fw-bolder mb-7">My Subscription</h2>

                      <p className="text-gray-400 fs-4 fw-bold mb-10">
                        There are no customers added yet. <br />
                        Kickstart your CRM by adding a your first customer
                      </p>

                      <a
                        href="/"
                        className="btn btn-primary"
                        data-bs-target="#kt_modal_upgrade_plan"
                        data-bs-toggle="modal"
                      >
                        Upgrade Plan
                      </a>
                    </div>

                    <div className="text-center px-4">
                      <img
                        src="assets/media/illustrations/sigma-1/18.png"
                        alt=""
                        className="mw-100 mh-300px"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="kt_aside_nav_tab_tasks"
                  role="tabpanel"
                >
                  <div className="mx-5">
                    <h3 className="fw-bolder text-dark mb-10 mx-0">
                      Tasks Overview
                    </h3>

                    <div className="mb-12">
                      <div className="d-flex align-items-center mb-7">
                        <div className="symbol symbol-50px me-5">
                          <span className="symbol-label bg-light-success">
                            <span className="svg-icon svg-icon-2x svg-icon-success">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                  fill="black"
                                />
                                <path
                                  d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <a
                            href="../../demo7/dist/pages/profile/overview.html"
                            className="
                                  text-gray-800 text-hover-primary
                                  fs-6
                                  fw-bold
                                "
                          >
                            Project Briefing
                          </a>
                          <span className="text-muted fw-bold">
                            Project Manager
                          </span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center mb-7">
                        <div className="symbol symbol-50px me-5">
                          <span className="symbol-label bg-light-warning">
                            <span className="svg-icon svg-icon-2x svg-icon-warning">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                  fill="black"
                                />
                                <path
                                  d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <a
                            href="../../demo7/dist/pages/profile/overview.html"
                            className="
                                  text-gray-800 text-hover-primary
                                  fs-6
                                  fw-bold
                                "
                          >
                            Concept Design
                          </a>
                          <span className="text-muted fw-bold">
                            Art Director
                          </span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center mb-7">
                        <div className="symbol symbol-50px me-5">
                          <span className="symbol-label bg-light-primary">
                            <span className="svg-icon svg-icon-2x svg-icon-primary">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z"
                                  fill="black"
                                />
                                <rect
                                  x="6"
                                  y="12"
                                  width="7"
                                  height="2"
                                  rx="1"
                                  fill="black"
                                />
                                <rect
                                  x="6"
                                  y="7"
                                  width="12"
                                  height="2"
                                  rx="1"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <a
                            href="../../demo7/dist/pages/profile/overview.html"
                            className="
                                  text-gray-800 text-hover-primary
                                  fs-6
                                  fw-bold
                                "
                          >
                            Functional Logics
                          </a>
                          <span className="text-muted fw-bold">
                            Lead Developer
                          </span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center mb-7">
                        <div className="symbol symbol-50px me-5">
                          <span className="symbol-label bg-light-danger">
                            <span className="svg-icon svg-icon-2x svg-icon-danger">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M11.2166 8.50002L10.5166 7.80007C10.1166 7.40007 10.1166 6.80005 10.5166 6.40005L13.4166 3.50002C15.5166 1.40002 18.9166 1.50005 20.8166 3.90005C22.5166 5.90005 22.2166 8.90007 20.3166 10.8001L17.5166 13.6C17.1166 14 16.5166 14 16.1166 13.6L15.4166 12.9C15.0166 12.5 15.0166 11.9 15.4166 11.5L18.3166 8.6C19.2166 7.7 19.1166 6.30002 18.0166 5.50002C17.2166 4.90002 16.0166 5.10007 15.3166 5.80007L12.4166 8.69997C12.2166 8.89997 11.6166 8.90002 11.2166 8.50002ZM11.2166 15.6L8.51659 18.3001C7.81659 19.0001 6.71658 19.2 5.81658 18.6C4.81658 17.9 4.71659 16.4 5.51659 15.5L8.31658 12.7C8.71658 12.3 8.71658 11.7001 8.31658 11.3001L7.6166 10.6C7.2166 10.2 6.6166 10.2 6.2166 10.6L3.6166 13.2C1.7166 15.1 1.4166 18.1 3.1166 20.1C5.0166 22.4 8.51659 22.5 10.5166 20.5L13.3166 17.7C13.7166 17.3 13.7166 16.7001 13.3166 16.3001L12.6166 15.6C12.3166 15.2 11.6166 15.2 11.2166 15.6Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.3"
                                  d="M5.0166 9L2.81659 8.40002C2.31659 8.30002 2.0166 7.79995 2.1166 7.19995L2.31659 5.90002C2.41659 5.20002 3.21659 4.89995 3.81659 5.19995L6.0166 6.40002C6.4166 6.60002 6.6166 7.09998 6.5166 7.59998L6.31659 8.30005C6.11659 8.80005 5.5166 9.1 5.0166 9ZM8.41659 5.69995H8.6166C9.1166 5.69995 9.5166 5.30005 9.5166 4.80005L9.6166 3.09998C9.6166 2.49998 9.2166 2 8.5166 2H7.81659C7.21659 2 6.71659 2.59995 6.91659 3.19995L7.31659 4.90002C7.41659 5.40002 7.91659 5.69995 8.41659 5.69995ZM14.6166 18.2L15.1166 21.3C15.2166 21.8 15.7166 22.2 16.2166 22L17.6166 21.6C18.1166 21.4 18.4166 20.8 18.1166 20.3L16.7166 17.5C16.5166 17.1 16.1166 16.9 15.7166 17L15.2166 17.1C14.8166 17.3 14.5166 17.7 14.6166 18.2ZM18.4166 16.3L19.8166 17.2C20.2166 17.5 20.8166 17.3 21.0166 16.8L21.3166 15.9C21.5166 15.4 21.1166 14.8 20.5166 14.8H18.8166C18.0166 14.8 17.7166 15.9 18.4166 16.3Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <a
                            href="../../demo7/dist/pages/profile/overview.html"
                            className="
                                  text-gray-800 text-hover-primary
                                  fs-6
                                  fw-bold
                                "
                          >
                            Development
                          </a>
                          <span className="text-muted fw-bold">DevOps</span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center mb-7">
                        <div className="symbol symbol-50px me-5">
                          <span className="symbol-label bg-light-info">
                            <span className="svg-icon svg-icon-2x svg-icon-info">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                  fill="black"
                                />
                                <path
                                  d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z"
                                  fill="black"
                                />
                                <path
                                  d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <a
                            href="../../demo7/dist/pages/profile/overview.html"
                            className="
                                  text-gray-800 text-hover-primary
                                  fs-6
                                  fw-bold
                                "
                          >
                            Testing
                          </a>
                          <span className="text-muted fw-bold">
                            QA Managers
                          </span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center mb-7">
                        <div className="symbol symbol-50px me-5">
                          <span className="symbol-label bg-light-success" />
                        </div>

                        <div className="d-flex flex-column">
                          <a
                            href="../../demo7/dist/pages/profile/overview.html"
                            className="
                                  text-gray-800 text-hover-primary
                                  fs-6
                                  fw-bold
                                "
                          >
                            HTML, CSS Coding
                          </a>
                          <span className="text-muted fw-bold">
                            Art Director
                          </span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50px me-5">
                          <span className="symbol-label bg-light-danger">
                            <span className="svg-icon svg-icon-2x svg-icon-danger">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M13 10.9128V3.01281C13 2.41281 13.5 1.91281 14.1 2.01281C16.1 2.21281 17.9 3.11284 19.3 4.61284C20.7 6.01284 21.6 7.91285 21.9 9.81285C22 10.4129 21.5 10.9128 20.9 10.9128H13Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.3"
                                  d="M13 12.9128V20.8129C13 21.4129 13.5 21.9129 14.1 21.8129C16.1 21.6129 17.9 20.7128 19.3 19.2128C20.7 17.8128 21.6 15.9128 21.9 14.0128C22 13.4128 21.5 12.9128 20.9 12.9128H13Z"
                                  fill="black"
                                />
                                <path
                                  opacity="0.3"
                                  d="M11 19.8129C11 20.4129 10.5 20.9129 9.89999 20.8129C5.49999 20.2129 2 16.5128 2 11.9128C2 7.31283 5.39999 3.51281 9.89999 3.01281C10.5 2.91281 11 3.41281 11 4.01281V19.8129Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <a
                            href="../../demo7/dist/pages/profile/overview.html"
                            className="
                                  text-gray-800 text-hover-primary
                                  fs-6
                                  fw-bold
                                "
                          >
                            ReactJS Developer
                          </a>
                          <span className="text-muted fw-bold">
                            Web, UI/UX Design
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="kt_aside_nav_tab_notifications"
                  role="tabpanel"
                >
                  <div className="mx-5">
                    <h3 className="fw-bolder text-dark mb-10 mx-0">
                      Notifications
                    </h3>

                    <div className="mb-12">
                      <div
                        className="
                              d-flex
                              align-items-center
                              bg-light-warning
                              rounded
                              p-5
                              mb-7
                            "
                      >
                        <span className="svg-icon svg-icon-warning me-5">
                          <span className="svg-icon svg-icon-1 svg-icon-warning">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                fill="black"
                              />
                              <path
                                d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </span>

                        <div className="flex-grow-1 me-2">
                          <a
                            href="../../demo7/dist/widgets/lists.html"
                            className="
                                  fw-bolder
                                  text-gray-800 text-hover-primary
                                  fs-6
                                "
                          >
                            Group lunch celebration
                          </a>
                          <span className="text-muted fw-bold d-block">
                            Due in 29 Days
                          </span>
                        </div>

                        <span className="fw-bolder text-warning py-1">
                          +28%
                        </span>
                      </div>

                      <div
                        className="
                              d-flex
                              align-items-center
                              bg-light-success
                              rounded
                              p-5
                              mb-7
                            "
                      >
                        <span className="svg-icon svg-icon-success me-5">
                          <span className="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM11.7 17.7L16.7 12.7C17.1 12.3 17.1 11.7 16.7 11.3C16.3 10.9 15.7 10.9 15.3 11.3L11 15.6L8.70001 13.3C8.30001 12.9 7.69999 12.9 7.29999 13.3C6.89999 13.7 6.89999 14.3 7.29999 14.7L10.3 17.7C10.5 17.9 10.8 18 11 18C11.2 18 11.5 17.9 11.7 17.7Z"
                                fill="black"
                              />
                              <path
                                d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </span>

                        <div className="flex-grow-1 me-2">
                          <a
                            href="../../demo7/dist/widgets/lists.html"
                            className="
                                  fw-bolder
                                  text-gray-800 text-hover-primary
                                  fs-6
                                "
                          >
                            Navigation optimization
                          </a>
                          <span className="text-muted fw-bold d-block">
                            Due in 2 Days
                          </span>
                        </div>

                        <span className="fw-bolder text-success py-1">
                          +50%
                        </span>
                      </div>

                      <div
                        className="
                              d-flex
                              align-items-center
                              bg-light-danger
                              rounded
                              p-5
                              mb-7
                            "
                      >
                        <span className="svg-icon svg-icon-danger me-5">
                          <span className="svg-icon svg-icon-1 svg-icon-danger">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z"
                                fill="black"
                              />
                              <rect
                                x="6"
                                y="12"
                                width="7"
                                height="2"
                                rx="1"
                                fill="black"
                              />
                              <rect
                                x="6"
                                y="7"
                                width="12"
                                height="2"
                                rx="1"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </span>

                        <div className="flex-grow-1 me-2">
                          <a
                            href="../../demo7/dist/widgets/lists.html"
                            className="
                                  fw-bolder
                                  text-gray-800 text-hover-primary
                                  fs-6
                                "
                          >
                            Humbert Bresnen
                          </a>
                          <span className="text-muted fw-bold d-block">
                            Due in 5 Days
                          </span>
                        </div>

                        <span className="fw-bolder text-danger py-1">-27%</span>
                      </div>

                      <div
                        className="
                              d-flex
                              align-items-center
                              bg-light-info
                              rounded
                              p-5
                              mb-7
                            "
                      >
                        <span className="svg-icon svg-icon-info me-5">
                          <span className="svg-icon svg-icon-1 svg-icon-info">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z"
                                fill="black"
                              />
                              <path
                                d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </span>

                        <div className="flex-grow-1 me-2">
                          <a
                            href="../../demo7/dist/widgets/lists.html"
                            className="
                                  fw-bolder
                                  text-gray-800 text-hover-primary
                                  fs-6
                                "
                          >
                            Air B &amp; B - Real Estate
                          </a>
                          <span className="text-muted fw-bold d-block">
                            Due in 8 Days
                          </span>
                        </div>

                        <span className="fw-bolder text-info py-1">+21%</span>
                      </div>

                      <div
                        className="
                              d-flex
                              align-items-center
                              bg-light-primary
                              rounded
                              p-5
                              mb-7
                            "
                      >
                        <span className="svg-icon svg-icon-primary me-5">
                          <span className="svg-icon svg-icon-1 svg-icon-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23"
                              height="24"
                              viewBox="0 0 23 24"
                              fill="none"
                            >
                              <path
                                d="M21 13V13.5C21 16 19 18 16.5 18H5.6V16H16.5C17.9 16 19 14.9 19 13.5V13C19 12.4 19.4 12 20 12C20.6 12 21 12.4 21 13ZM18.4 6H7.5C5 6 3 8 3 10.5V11C3 11.6 3.4 12 4 12C4.6 12 5 11.6 5 11V10.5C5 9.1 6.1 8 7.5 8H18.4V6Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M21.7 6.29999C22.1 6.69999 22.1 7.30001 21.7 7.70001L18.4 11V3L21.7 6.29999ZM2.3 16.3C1.9 16.7 1.9 17.3 2.3 17.7L5.6 21V13L2.3 16.3Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </span>

                        <div className="flex-grow-1 me-2">
                          <a
                            href="../../demo7/dist/widgets/lists.html"
                            className="
                                  fw-bolder
                                  text-gray-800 text-hover-primary
                                  fs-6
                                "
                          >
                            B &amp; Q - Food Company
                          </a>
                          <span className="text-muted fw-bold d-block">
                            Due in 6 Days
                          </span>
                        </div>

                        <span className="fw-bolder text-primary py-1">
                          +12%
                        </span>
                      </div>

                      <div
                        className="
                              d-flex
                              align-items-center
                              bg-light-danger
                              rounded
                              p-5
                            "
                      >
                        <span className="svg-icon svg-icon-danger me-5">
                          <span className="svg-icon svg-icon-1 svg-icon-danger">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                fill="black"
                              />
                              <path
                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </span>

                        <div className="flex-grow-1 me-2">
                          <a
                            href="../../demo7/dist/widgets/lists.html"
                            className="
                                  fw-bolder
                                  text-gray-800 text-hover-primary
                                  fs-6
                                "
                          >
                            Nexa - Next generation
                          </a>
                          <span className="text-muted fw-bold d-block">
                            Due in 4 Days
                          </span>
                        </div>

                        <span className="fw-bolder text-danger py-1">+34%</span>
                      </div>
                    </div>
                  </div>
                </div>

                {location.pathname === '/videos' && <Folders />}
                {location.pathname === '/analytics' && <Searches />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        className="
              btn btn-sm btn-icon
              bg-body
              btn-color-gray-700 btn-active-primary
              position-absolute
              translate-middle
              start-100
              end-0
              bottom-0
              shadow-sm
              d-none d-lg-flex
            "
        data-kt-toggle="true"
        data-kt-toggle-state="active"
        data-kt-toggle-target="body"
        data-kt-toggle-name="aside-minimize"
        style={{ marginBottom: '1.35rem' }}
      >
        <span className="svg-icon svg-icon-2 rotate-180">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect
              opacity="0.5"
              x="6"
              y="11"
              width="13"
              height="2"
              rx="1"
              fill="black"
            />
            <path
              d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
              fill="black"
            />
          </svg>
        </span>
      </button>
    </div>
  );
}
