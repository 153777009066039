/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL } from '../../constants/main';

const initialState = {
  faces: [],
  selectedFaces: [],
  foundVideos: [],
  isLoading: false,
};

export const getFaces = createAsyncThunk('getFaces', async () => {
  const url = `${API_URL}/search-faces`;
  const response = await axios.get(url);
  // console.log(response);
  return response.data;
});

export const searchFaces = createAsyncThunk('searchFaces', async (faces) => {
  const url = `${API_URL}/search-faces/search`;
  const response = await axios.post(url, { faces });
  return response.data;
});

export const analyticsSlice = createSlice({
  name: 'getFaces',
  initialState,
  reducers: {
    selectFaces: (state, action) => {
      state.selectedFaces.push(action.payload);
    },
    deleteSelectedFaces: (state, action) => {
      state.selectedFaces.filter((el, i) => {
        if (el.faceId === action.payload.faceId) {
          state.selectedFaces.splice(i, 1);
        }
        return false;
      });
    },
    deleteFoundVideos: (state) => {
      state.foundVideos = [];
    },
  },
  extraReducers: {
    [getFaces.pending]: (state) => {
      state.isLoading = true;
    },
    [getFaces.fulfilled]: (state, action) => {
      if (action.payload) {
        state.faces = action.payload.data;
      }
      state.isLoading = false;
    },
    [getFaces.rejected]: (state, action) => {
      state.isLoading = false;
      console.log(action.payload);
    },
    [searchFaces.pending]: (state) => {
      state.isLoading = true;
    },
    [searchFaces.fulfilled]: (state, action) => {
      if (action.payload) {
        state.foundVideos = action.payload;
      }
      state.isLoading = false;
    },
    [searchFaces.rejected]: (state, action) => {
      state.isLoading = false;
      console.log(action.payload);
    },
  },
});

export const { selectFaces, deleteSelectedFaces, deleteFoundVideos } =
  analyticsSlice.actions;

export default analyticsSlice.reducer;
