/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

export default function Filters({ sourceType, setSourceType, folders = [] }) {
  return (
    <div className={classes.Filters}>
      <h3 className="text-muted">Filters</h3>
      <div className={classes.selectors}>
        <div className={classes.selector}>
          <label htmlFor="source">Source: </label>
          <select
            name="source"
            id="source"
            value={sourceType}
            onChange={(event) => setSourceType(event.target.value)}
          >
            <option value="All Videos">All Videos</option>
            <option value="Select Folder">Select Folder</option>
            <option value="Enter DocID">Enter DocID</option>
          </select>
        </div>
        {sourceType === 'Select Folder' && (
          <div className={classes.selector}>
            <select name="folder" id="folder">
              {folders.map((folder) => {
                console.log(folder);
                return (
                  <option value={folder.id} key={folder.id}>
                    {folder.folderName}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className={classes.selector}>
          <label htmlFor="folder">Show Entity Types: </label>
          <select name="folder" id="folder">
            <option value="Folder 1">Faces</option>
          </select>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <button
          type="button"
          className={classnames('btn btn-sm fw-bolder btn-primary')}
        >
          Apply
        </button>
      </div>
    </div>
  );
}
