/* eslint-disable import/named */
import React, { useEffect } from 'react';

import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlinePlus } from 'react-icons/ai';
import { Portal } from 'react-portal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ConfirmFolderRemoval from '../../modals/ConfirmFolderRemoval';
import { getFolders } from '../../redux/slices/foldersSlice';

import Folder from './Folder';

import classes from './styles.module.scss';

export default function Folders() {
  const folders = useSelector((state) => state.folders.folders);
  const currentFolder = useSelector((state) => state.folders.currentFolder);
  const folderError = useSelector((state) => state.folders.error);
  const dispatch = useDispatch();

  const notifyError = (message) => toast.error(message);

  useEffect(() => {
    if (folderError.message) {
      notifyError(folderError.message);
    }
  }, [folderError]);

  useEffect(() => {
    dispatch(getFolders());
  }, []);

  return (
    <div
      className={classnames(
        'tab-pane',
        'fade',
        'show',
        'active',
        classes.Folders
      )}
      id="kt_aside_nav_tab_folders"
      role="tabpanel"
    >
      <div className="mx-5">
        <h3 className="fw-bolder text-dark mx-0 mb-10">Manage Videos</h3>
        <AiOutlinePlus
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_create_folder"
          className={classes.plusIcon}
          size={22}
        />
        <div className="mb-12">
          <Folder title="All Videos" currentFolder={currentFolder} />
          {folders &&
            folders.map((folder) => (
              <Folder
                key={folder.id}
                id={folder.id}
                title={folder.folderName}
                currentFolder={currentFolder}
              />
            ))}
        </div>
      </div>
      <Portal>
        <ConfirmFolderRemoval />
      </Portal>
      <Portal>
        <ToastContainer />
      </Portal>
    </div>
  );
}
