/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classnames from 'classnames';
import classes from '../FileOrDirectoryUpload/styles.module.scss';

// import classnames from 'classnames';
//
// import classes from './styles.module.scss';

export default function MatchedVideosModal() {
  return (
    <div
      className="modal fade"
      id="kt_modal_matched_video"
      tabIndex="-1"
      aria-hidden="true"
      style={{ top: 75 }}
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content rounded">
          <div
            className={classnames(
              'modal-header justify-content-between border-0 pb-1 px-10 mb-8',
              classes.header
            )}
          >
            <h1 className="mb-3">Matched Video</h1>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
