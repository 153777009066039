import React from 'react';

import Header from './Header';
import Sidebar from './Sidebar';

import classes from './styles.module.scss';

export default function Layout({ children }) {
  return (
    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <Header />
      <Sidebar />
      <div className={classes.content}>{children}</div>
    </div>
  );
}
