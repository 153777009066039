/* eslint-disable no-param-reassign */
// import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

// import { API_URL } from '../../constants/main';

const initialState = {
  searches: ['Test Search', 'Another Search'],
  currentSearch: '',
  searchToRemove: '',
  error: { message: '' },
};

/* export const createFolder = createAsyncThunk(
  'createFolder',
  async ({ folderName }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/folders`, { folderName });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFolders = createAsyncThunk('getFolders', async () => {
  const response = await axios.get(`${API_URL}/folders`);
  return response.data;
});

export const updateFolder = createAsyncThunk(
  'updateFolder',
  async ({ folderName, folderId }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${API_URL}/folders/${folderId}`, {
        folderName,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFolder = createAsyncThunk(
  'deleteFolder',
  async (action, { getState }) => {
    const { folders } = getState();
    const response = await axios.delete(
      `${API_URL}/folders/${folders.folderToRemove}`
    );
    return response.data;
  }
); */

export const searchesSlice = createSlice({
  name: 'searches',
  initialState,
  reducers: {
    createSearch: (state, action) => {
      state.searches.push(action.payload);
    },
    setCurrentSearch: (state, action) => {
      state.currentSearch = action.payload;
    },
    setSearchToRemove: (state, action) => {
      state.searchToRemove = action.payload;
    },
    deleteSearch: (state) => {
      const searchIndex = state.searches.findIndex(
        (search) => search === state.searchToRemove
      );
      if (searchIndex !== -1) {
        state.searches.splice(searchIndex, 1);
      }
    },
    renameSearch: (state, action) => {
      const searchIndex = state.searches.findIndex(
        (search) => search === action.payload.title
      );
      if (searchIndex !== -1) {
        state.searches[searchIndex] = action.payload.searchName;
      }
    },
  },
  /*  extraReducers: {
    [createFolder.pending]: (state) => {
      state.isLoading = true;
    },
    [createFolder.fulfilled]: (state, action) => {
      state.folders.push(action.payload);
      state.isLoading = false;
    },
    [createFolder.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = {
        message: action?.payload?.message || 'Something went wrong',
      };
    },
    [getFolders.pending]: (state) => {
      state.isLoading = true;
    },
    [getFolders.fulfilled]: (state, action) => {
      state.folders = action.payload;
      state.isLoading = false;
    },
    [getFolders.rejected]: (state) => {
      state.isLoading = false;
      console.log('Something went wrong');
    },
    [updateFolder.pending]: (state) => {
      state.isLoading = true;
    },
    [updateFolder.fulfilled]: (state, action) => {
      const folderIndex = state.folders.findIndex(
        (folder) => folder.id === action.payload.id
      );
      if (folderIndex !== -1) {
        state.folders[folderIndex] = action.payload;
      }
      state.isLoading = false;
    },
    [updateFolder.rejected]: (state, action) => {
      state.isLoading = false;
      console.log('Something went wrong');
      state.error = {
        message: action?.payload?.message || 'Something went wrong',
      };
    },
    [deleteFolder.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteFolder.fulfilled]: (state, action) => {
      const folderIndex = state.folders.findIndex(
        (folder) => folder.id === action.payload.id
      );
      if (folderIndex !== -1) {
        state.folders.splice(folderIndex, 1);
      }
      state.isLoading = false;
    },
    [deleteFolder.rejected]: (state) => {
      state.isLoading = false;
      console.log('Something went wrong');
    },
  }, */
});

export const {
  createSearch,
  setCurrentSearch,
  setSearchToRemove,
  renameSearch,
  deleteSearch,
} = searchesSlice.actions;
export default searchesSlice.reducer;
