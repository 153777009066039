import React from 'react';

import classnames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import classes from './styles.module.scss';
import EntitiesItem from '../EntitiesItem';
import { searchFaces } from '../../../redux/slices/analyticsSlice';

export default function Entities() {
  const dispatch = useDispatch();
  const faces = useSelector((state) => state.faces.faces);
  const markedFaces = useSelector((state) => state.faces.selectedFaces);
  const isLoading = useSelector((state) => state.faces.isLoading);

  const handleSearchFaces = () => {
    dispatch(searchFaces(markedFaces));
  };

  return (
    <div className={classes.Entities}>
      <h3 className="text-muted">Entities</h3>

      {faces && (
        <ul className={classes.entitiesList}>
          {faces.map((el) => {
            let checked = false;
            markedFaces.forEach((x) => {
              if (x.faceId === el.grp_id) checked = true;
            });
            return (
              <EntitiesItem key={el.grp_id} data={el} isChecked={checked} />
            );
          })}
        </ul>
      )}

      <div className={classes.buttonContainer}>
        <button
          type="button"
          className={classnames('btn btn-sm fw-bolder btn-primary')}
          onClick={handleSearchFaces}
          disabled={isLoading}
        >
          Find Videos
        </button>
      </div>
      {isLoading && 'Loading...'}
    </div>
  );
}
