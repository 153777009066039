/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';

export function configureStore(initialState) {
  const store = createStore(
    reducers,
    initialState,
    compose(applyMiddleware(thunk))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
 */

import { configureStore } from '@reduxjs/toolkit';

import foldersSlice from './slices/foldersSlice';
import videosSlice from './slices/videosSlice';
import searchesSlice from './slices/searchesSlice';
import analyticsSlice from './slices/analyticsSlice';

export const store = configureStore({
  reducer: {
    folders: foldersSlice,
    videos: videosSlice,
    searches: searchesSlice,
    faces: analyticsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
