/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';

import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import classes from './styles.module.scss';
import { addVideoToStopUploadingVideos } from '../../../redux/slices/videosSlice';

export default function Video({ video, setVideos }) {
  const [preview, setPreview] = useState();
  const uploadStatus = useSelector((state) => state.videos.uploadStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    setPreview(URL.createObjectURL(video));
  }, [video]);

  const stopUpload = () => {
    // uploadStatus[video.name]?.cancelToken.cancel();
    dispatch(addVideoToStopUploadingVideos(video));
    setVideos((prevVideos) => {
      const newVideos = prevVideos.filter(
        (videoFromState) => videoFromState !== video
      );
      return newVideos;
    });
  };

  return (
    <div
      className={classnames(
        classes.Video,
        uploadStatus[video.name]?.error && classes.alreadyExists
      )}
    >
      <video className={classes.preview} src={preview}>
        Hello
      </video>
      <div className={classes.details}>
        <div className={classes.header}>
          <h1>{video.name}</h1>
          <span className={classes.size}>
            {Math.ceil(video.size / 1024 / 1024)}mb
          </span>
          {(uploadStatus[video.name]?.progress < 100 ||
            !uploadStatus[video.name]?.progress) && (
            <MdClose
              size={16}
              className={classes.closeIcon}
              onClick={stopUpload}
            />
          )}
        </div>
        <progress value={uploadStatus[video.name]?.progress} max={100} />
        <div className={classes.uploadStatus}>
          <span>{uploadStatus[video.name]?.progress}% done</span>
          <span>{uploadStatus[video.name]?.speed}KB/sec</span>
        </div>
      </div>
    </div>
  );
}
