/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL } from '../../constants/main';

const initialState = {
  videos: [],
  isLoading: false,
  uploadStatus: {},
  videosToStopUploading: [],
  videoToRemove: null,
  currentPage: 0,
};

export const addVideo = createAsyncThunk(
  'addVideo',
  async (
    {
      formData,
      updateUploadStatus,
      removeVideoFromStopUploadingVideos,
      isFilePathOnly,
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const startDate = Date.now();
    const cancelToken = axios.CancelToken.source();

    const url = isFilePathOnly
      ? `${API_URL}/videos/filepath`
      : `${API_URL}/videos`;

    try {
      const response = await axios.post(url, formData, {
        onUploadProgress: (progressEvent) => {
          const video = isFilePathOnly
            ? { name: formData.originalName }
            : formData.get('video');
          const { videos } = getState();
          if (videos.videosToStopUploading.includes(video)) {
            cancelToken.cancel();
            dispatch(removeVideoFromStopUploadingVideos(video));
            setTimeout(() => {
              dispatch(
                updateUploadStatus({ name: video.name, speed: 0, progress: 0 })
              );
            }, 1000);
          }
          /* console.log(
            progressEvent.loaded / 1024 / ((Date.now() - startDate) / 1000)
          ); */
          dispatch(
            updateUploadStatus({
              name: video.name,
              progress: Math.floor(
                (progressEvent.loaded / progressEvent.total) * 100
              ),
              speed: Math.round(
                progressEvent.loaded /
                  1024 /
                  ((Date.now() - startDate) / 1000 < 1
                    ? 1
                    : (Date.now() - startDate) / 1000)
              ),
            })
          );
        },
        cancelToken: cancelToken.token,
      });
      return isFilePathOnly ? null : response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/* export const addVideoFilePath = createAsyncThunk(
  'addVideoFilePath',
  async (
    { formData, updateUploadStatus, removeVideoFromStopUploadingVideos },
    { dispatch, getState, rejectWithValue }
  ) => {
    const startDate = Date.now();
    const cancelToken = axios.CancelToken.source();

    try {
      const response = await axios.post(`${API_URL}/videos`, formData, {
        onUploadProgress: (progressEvent) => {
          const video = formData.get('video');
          const { videos } = getState();
          if (videos.videosToStopUploading.includes(video)) {
            cancelToken.cancel();
            dispatch(removeVideoFromStopUploadingVideos(video));
            setTimeout(() => {
              dispatch(
                updateUploadStatus({ name: video.name, speed: 0, progress: 0 })
              );
            }, 1000);
          }

          dispatch(
            updateUploadStatus({
              name: video.name,
              progress: Math.floor(
                (progressEvent.loaded / progressEvent.total) * 100
              ),
              speed: Math.round(
                progressEvent.loaded /
                  1024 /
                  ((Date.now() - startDate) / 1000 < 1
                    ? 1
                    : (Date.now() - startDate) / 1000)
              ),
            })
          );
        },
        cancelToken: cancelToken.token,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
); */

export const getVideos = createAsyncThunk(
  'getVideos',
  async ({ folderId }, { getState }) => {
    const { videos } = getState();

    const url = folderId
      ? `${API_URL}/videos/?pageIndex=${videos.currentPage}&folderId=${folderId}`
      : `${API_URL}/videos?pageIndex=${videos.currentPage}`;
    const response = await axios.get(url);
    return response.data;
  }
);

export const removeVideo = createAsyncThunk(
  'removeVideo',
  async (action, { getState }) => {
    const { videos } = getState();
    const response = await axios.delete(
      `${API_URL}/videos/${videos.videoToRemove.id}`
    );
    return response.data;
  }
);

export const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    resetCurrentPage: (state) => {
      state.currentPage = 0;
      state.videos = [];
    },
    setVideoToRemove: (state, action) => {
      state.videoToRemove = action.payload;
    },
    updateUploadStatus: (state, action) => {
      state.uploadStatus[action.payload.name] = {
        progress: action.payload.progress,
        speed: action.payload.speed,
      };
    },
    removeFromUploadStatus: (state, action) => {
      delete state.uploadStatus[action.payload.name];
    },
    addVideoToStopUploadingVideos: (state, action) => {
      state.videosToStopUploading.push(action.payload);
    },
    removeVideoFromStopUploadingVideos: (state, action) => {
      const index = state.videosToStopUploading.findIndex(
        (video) => video === action.payload
      );
      if (index !== -1) {
        state.videosToStopUploading.splice(index, 1);
      }
    },
  },
  extraReducers: {
    [addVideo.pending]: (state) => {
      state.isLoading = true;
    },
    [addVideo.fulfilled]: (state, action) => {
      if (action.payload) {
        state.videos.push(action.payload);
      }
      state.isLoading = false;
    },
    [addVideo.rejected]: (state, action) => {
      state.isLoading = false;
      console.log(action.payload);
      if (action.payload) {
        state.uploadStatus[JSON.parse(action.payload.message).name] = {
          progress: 0,
          speed: 0,
          error: JSON.parse(action.payload.message).message,
        };
      } else {
        console.log('Something went wrong');
      }
    },
    [getVideos.pending]: (state) => {
      state.isLoading = true;
    },
    [getVideos.fulfilled]: (state, action) => {
      const newVideos = action.payload.filter(
        (newVideo) => !state.videos.some((video) => video.id === newVideo.id)
      );
      state.videos.push(...newVideos);
      // state.videos = action.payload;
      state.isLoading = false;
      // eslint-disable-next-line no-plusplus
      state.currentPage++;
    },
    [getVideos.rejected]: (state) => {
      state.isLoading = false;
      console.log('Something went wrong');
    },
    [removeVideo.pending]: (state) => {
      state.isLoading = true;
    },
    [removeVideo.fulfilled]: (state, action) => {
      const index = state.videos.findIndex(
        (video) => video.id === action.payload.id
      );
      if (index !== -1) {
        state.videos.splice(index, 1);
      }
      state.isLoading = false;
    },
    [removeVideo.rejected]: (state) => {
      state.isLoading = false;
      console.log('Something went wrong');
    },
  },
});

export const {
  updateUploadStatus,
  addVideoToStopUploadingVideos,
  removeVideoFromStopUploadingVideos,
  setVideoToRemove,
  removeFromUploadStatus,
  resetCurrentPage,
} = videosSlice.actions;

export default videosSlice.reducer;
